import React from "react";
import { graphql } from "gatsby";
import { ProductView } from "~/views";

export default function Product(prop, ...args) {
  return <ProductView {...prop} />;
}

export const query = graphql`
  query ($id: String, $model: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    product(id: { eq: $id }) {
      name
      model
      category
      tags {
        ko
        en
      }
      images {
        big
        small
      }
      description {
        ko {
          Features
          Options
        }
        en {
          Features
          Options
        }
      }
      seo {
        ko {
          description
          keywords
        }
        en {
          description
          keywords
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: $model } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, height: 600)
        }
      }
    }
  }
`;
